<template>
  <dash-page-new
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
      :title="$t('AllNews')"
      :subtitle="$t('News')"
      :root="$t('Dashboard')"
      icon="mdi-newspaper"
  >

    <template #header_action>
      <v-btn
          @click="addNews"
          :color="wsATTENTION"
          class="noCaps"
          dark
      >
        <v-icon small>mdi-plus</v-icon>
        {{ $t('AddNews') }}
      </v-btn>
    </template>

    <template #default>

      <ws-data-table
          :headers="headers"
          :items="news"
          :row-action="(item) => $router.push(localeLink('dashboard/news/' + item.uuid)) "
          class="mt-5"
      >
        <template #item.title="{item}">
          <h5 :style="`color : ${wsACCENT}`">
            {{ item.title }}
          </h5>
        </template>

        <template #item.published="{item}">
          <h5> {{ PARSE_DATE(item.date_published , false , true )}}</h5>
        </template>

      </ws-data-table>
    </template>




  </dash-page-new>

</template>

<script>

import {mapActions} from "vuex";
export default {
  name: "DashboardNews",
  data() {
    return {
      news : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'title' },
        { text : this.$t('Date') , value : 'published' }
      ]
    }
  },
  methods : {
    ...mapActions('news', [
        'GET_NEWS_MAIN',
        'ADD_EDIT_NEWS_MAIN'
        ]
    ),



    async addNews() {
      this.loading = true
      let result = await this.ADD_EDIT_NEWS_MAIN()
      if ( !result ) {
        return
      }
      this.news.unshift(result)
    }

  },
  async mounted(){
    let result = await this.GET_NEWS_MAIN(this.is_westudy ? 'westudy' : 'fine')
    this.news = result.news
  }
}
</script>

<style scoped>

</style>